import React from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import './index.scss'

const AppPopupPreview = ({ data }) => {
  return (
    <div
      className="rounded-lg border py-4 relative"
      style={{
        height: 'fit-content',
        width: '375px',
        minHeight: '660px',
        backgroundColor: '#00000080',
      }}
    >
      <div
        className="absolute top-8 right-4 p-2 rounded-full flex items-center justify-center font-semibold text-white"
        style={{ backgroundColor: '#0000005C', width: '40px', height: '40px' }}
      >
        <CloseOutlined />
      </div>
      <div class="promotion-banner relative text-center" style={{ marginTop: '223px' }}>
        <div className="absolute" style={{ top: '-76px', right: '90px' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="61"
            viewBox="0 0 60 61"
            fill="none"
          >
            <g filter="url(#filter0_d_102_39)">
              <path
                d="M30 10L30.2787 10.527C34.6998 18.887 41.5847 25.6852 50 30C41.5847 34.3149 34.6998 41.1131 30.2787 49.473L30 50L29.4124 48.9543C24.8908 40.9072 18.1528 34.3282 9.99998 30V30C18.1528 25.6719 24.8907 19.0929 29.4124 11.0458L30 10Z"
                fill={data?.modalSparkle}
              />
            </g>
            <defs>
              <filter
                id="filter0_d_102_39"
                x="0.303016"
                y="0.909077"
                width="59.394"
                height="59.394"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="0.606062" />
                <feGaussianBlur stdDeviation="4.84849" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 1 0 0 0 0 0.907993 0 0 0 0 0.799984 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_102_39"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_102_39"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
        <div className="absolute" style={{ top: '-76px', left: '122px' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
          >
            <g filter="url(#filter0_d_102_41)">
              <path
                d="M23 9.00001L23.1839 9.34784C26.1019 14.8654 30.6459 19.3523 36.2 22.2C30.6459 25.0478 26.1019 29.5347 23.1839 35.0523L23 35.4001L22.6122 34.7099C19.6279 29.3988 15.1808 25.0566 9.79996 22.2L9.79996 22.2C15.1808 19.3434 19.6279 15.0013 22.6122 9.69022L23 9.00001Z"
                fill={data?.modalSparkle}
              />
            </g>
            <circle cx="2.4" cy="24.6" r="2.4" fill={data?.modalSparkle} />
            <circle cx="15.4" cy="42.4" r="2.4" fill={data?.modalSparkle} />
            <defs>
              <filter
                id="filter0_d_102_41"
                x="0.200048"
                y="-3.57628e-07"
                width="45.5999"
                height="45.6"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="0.6" />
                <feGaussianBlur stdDeviation="4.8" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 1 0 0 0 0 0.907993 0 0 0 0 0.799984 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_102_41"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_102_41"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
        <div
          style={{
            height: '12px',
            width: '100%',
            background: data?.modalRayUp,
          }}
        ></div>
        <div
          style={{
            height: '83px',
            width: '100%',
            lineHeight: '22px',
            background: data?.modalLightUp,
          }}
        ></div>
        <div
          style={{
            height: '83px',
            width: '100%',
            lineHeight: '36px',
            background: data?.modalLightDown,
          }}
        ></div>
        <div
          style={{
            height: '12px',
            width: '100%',
            background: data?.modalRayDown,
          }}
        ></div>
        <div className="absolute top-3 text-center w-full" style={{ padding: '16px 44px' }}>
          <div
            style={{
              fontSize: '18px',
              fontWeight: 600,
              lineHeight: '24px',
              textAlign: 'center',
              width: '100%',
              color: data?.headlineColor,
            }}
          >
            {data?.headlineContent}
          </div>
          <div
            style={{
              fontSize: '28px',
              fontWeight: 700,
              letterSpacing: '0.5px',
              lineHeight: '36px',
              textAlign: 'center',
              width: '100%',
              color: data?.titleColor,
            }}
          >
            {data?.titleContent}
          </div>
          <div
            style={{
              fontSize: '16px',
              fontWeight: 400,
              textAlign: 'center',
              width: '100%',
              color: data?.subtitleColor,
            }}
          >
            {data?.subtitleContent}
          </div>
        </div>
      </div>
      <div className="flex relative items-center justify-center w-full">
        <div
          className=" font-semibold text-lg px-5 py-2 mt-10 cursor-pointer"
          style={{
            height: '40px',
            color: data?.buttonLabelColor || 'white',
            background: data?.buttonBackgroundColor,
            borderRadius: '32px',
          }}
        >
          <span
            style={{
              color: data?.buttonLabelColor || 'white',
            }}
          >
            {data?.buttonLabel}
          </span>
        </div>
        <div className="absolute" style={{ top: '12px', right: '150px' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="37"
            viewBox="0 0 38 37"
            fill="none"
          >
            <g filter="url(#filter0_d_102_45)">
              <path
                d="M22.4141 6.00001L22.5294 6.21809C24.3588 9.67737 27.2077 12.4904 30.6899 14.2759C27.2077 16.0613 24.3588 18.8744 22.5294 22.3337L22.4141 22.5517L22.1709 22.119C20.2999 18.7892 17.5118 16.0668 14.1382 14.2759V14.2759C17.5118 12.4849 20.2999 9.76258 22.1709 6.43274L22.4141 6.00001Z"
                fill={data?.modalSparkle}
              />
            </g>
            <g filter="url(#filter1_d_102_45)">
              <circle cx="8.65518" cy="27.6552" r="1.65518" fill={data?.modalSparkle} />
            </g>
            <defs>
              <filter
                id="filter0_d_102_45"
                x="7.79334"
                y="0.0517133"
                width="29.2414"
                height="29.2414"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="0.396552" />
                <feGaussianBlur stdDeviation="3.17242" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 1 0 0 0 0 0.907993 0 0 0 0 0.799984 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_102_45"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_102_45"
                  result="shape"
                />
              </filter>
              <filter
                id="filter1_d_102_45"
                x="0.655161"
                y="20.0517"
                width="16"
                height="16"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="0.396552" />
                <feGaussianBlur stdDeviation="3.17242" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 1 0 0 0 0 0.907993 0 0 0 0 0.799984 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_102_45"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_102_45"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default AppPopupPreview

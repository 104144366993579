import React from 'react'

const AppInPagePreview = ({ data, initAiData }) => {
  return (
    <div className=" rounded-lg border p-4" style={{ height: 'fit-content' }}>
      <div className=" font-semibold mb-4" style={{ fontSize: '20px' }}>
        Preview
      </div>
      <div
        className=" rounded-lg bg-gray-200 flex items-center gap-2 justify-center"
        style={{
          width: '500px',
          height: '400px',
        }}
      >
        <div className="bg-white p-2" style={{ width: '375px' }}>
          <div className=" flex gap-2 justify-between items-center bg-blue-100 p-2 rounded-lg">
            <div className="w-[48px] h-[48px] rounded-lg">
              <img
                className="w-[48px] h-[48px] rounded-lg"
                style={{
                  width: '48px',
                  height: '48px',
                }}
                src={
                  data?.mode === 'generate' || data?.type === 'generate'
                    ? data?.productPreview?.image || initAiData?.image
                    : data?.imageUrl || initAiData?.image
                }
                alt=""
              ></img>
            </div>
            <div className=" flex flex-col" style={{ width: '207px' }}>
              <div className=" line-clamp-1 font-semibold leading-4">
                {data?.mode === 'generate' || data?.type === 'generate'
                  ? data?.productPreview?.headline || initAiData?.headline
                  : data?.title ||
                    initAiData?.headline ||
                    '{Enter in-page title w/ max 1 lines} bla bla bla bla '}
              </div>
              <div className=" line-clamp-2 leading-4">
                {data?.mode === 'generate' || data?.type === 'generate'
                  ? data?.productPreview?.body || initAiData?.body
                  : data?.content ||
                    initAiData?.body ||
                    '{Enter description w/ max 2 lines} bla bla bla w/ max 2 lines bla bla bla bla bla bla bla bla bla bla'}
              </div>
            </div>
            <div
              className="cursor-pointer text-center bg-brand_primary text-sm !leading-4 p-2 font-semibold text-white"
              style={{ borderRadius: '32px', width: '72px' }}
            >
              <a
                href={
                  data?.mode === 'generate' || data?.type === 'generate'
                    ? data?.productPreview?.ctaRedirectLink || initAiData?.ctaRedirectDeepLink
                    : data?.ctaRedirectLink || data?.ctaRedirectDeepLink
                }
                target="_blank"
              >
                {' '}
                {data?.mode === 'generate' || data?.type === 'generate'
                  ? data?.productPreview?.cta || initAiData?.cta
                  : data?.ctaTitle || 'CTA label'}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppInPagePreview

import React, { useState } from 'react'
import { Typography, Select, Skeleton } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'

import iosMessagesIcon from 'Assets/images/ios-messages-icon.png'
import androidMessagesIcon from 'Assets/images/android-sms-icon.png'
import { ReactComponent as IOSMessageIcon } from 'Assets/images/icons/iosmessage.svg'
import { ReactComponent as AndroidMessageIcon } from 'Assets/images/icons/AndroidSMS.svg'
import { DownOutlined } from '@ant-design/icons'
import './index.scss'
import { brandVars } from 'Utils/brandVars'
import Android from 'Assets/images/Android.png'
import IOS from 'Assets/images/iPhoneX.png'

const { Option } = Select
const { Title } = Typography

const platforms = [
  { label: 'Android', value: 'android' },
  { label: 'iOS', value: 'ios' },
]

const SMS = ({ initData }) => {
  const { message_content } = initData
  const [device, setDevice] = useState(platforms[0].value)
  const [loading, setLoading] = useState(false)

  const onChangeDevice = async (value) => {
    setLoading(true)
    await new Promise((resolve) =>
      setTimeout(() => {
        resolve(setDevice(value))
      }, 1000)
    )
    setLoading(false)
  }

  return (
    <div className="w-full SMS-ContentPreview">
      <div className="flex justify-between items-center">
        <span className="font-semibold text-lg">Preview</span>
        <div className="platform-select text-brand_primary font-semibold">
          <Select options={platforms} onChange={(value) => onChangeDevice(value)} value={device} />
        </div>
      </div>
      <div className="preview-screen">
        <div>
          {' '}
          <div className="SMSPreviewComponent">
            <div className="SMSScreen">
              <Skeleton active loading={loading}>
                {device == 'ios' && (
                  <div className="SMS-IOSView-Wrapper">
                    <img src={IOS}></img>
                    <div className="SMS-IOSView-MessageBox">
                      <div className="flex justify-between">
                        <div className="flex   items-center">
                          <div className="SMS-IOSView-MessageBox-Icon">
                            <IOSMessageIcon></IOSMessageIcon>
                          </div>
                          <div
                            className="SMS-IOSView-MessageBox-Title ml-1"
                            style={{ fontSize: 11, color: '#9c0d0a' }}
                          >
                            Tin nhắn
                          </div>
                        </div>
                        <div
                          className="SMS-IOSView-MessageBox-Time"
                          style={{ fontSize: 11, color: '#9c0d0a' }}
                        >
                          now
                        </div>
                      </div>
                      <div>
                        <div
                          className="SMS-IOSView-MessageBox-CompanyName"
                          style={{ fontWeight: 600 }}
                        >
                          King Food
                        </div>
                        <div
                          className="SMS-IOSView-MessageBox-Content"
                          style={{ wordBreak: 'break-all' }}
                        >
                          {message_content || 'Enter Description'}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {device == 'android' && (
                  <div className="SMS-AndroidView-Wrapper">
                    <img src={Android}></img>
                    <div className="SMS-AndroidView-MessageBox">
                      <div className="flex justify-between">
                        <div className="flex items-center">
                          <div className="SMS-AndroidView-MessageBox-Icon">
                            <AndroidMessageIcon></AndroidMessageIcon>
                          </div>
                          <div
                            className="SMS-AndroidView-MessageBox-Title ml-1"
                            style={{ fontSize: 11, color: '#516FFF' }}
                          >
                            Tin nhắn
                          </div>

                          <div
                            className="SMS-AndroidView-MessageBox-Time ml-1"
                            style={{ fontSize: 11, color: '#595959' }}
                          >
                            now
                          </div>
                        </div>
                        <DownOutlined style={{ fontSize: 11, marginRight: 4 }} />
                      </div>
                      <div>
                        <div
                          className="SMS-AndroidView-MessageBox-CompanyName"
                          style={{ fontWeight: 600 }}
                        >
                          King Food
                        </div>
                        <div
                          className="SMS-AndroidView-MessageBox-Content"
                          style={{ wordBreak: 'break-all' }}
                        >
                          {message_content || 'Enter Description'}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SMS
